import clsx from 'clsx';
import { ReactNode, useRef } from 'react';

import { FooterQuery, FormQuery, NavigationQuery } from '@/api';
import { Footer } from '@/components/footer/Footer';
import { Form } from '@/components/form/Form';
import { Navigation } from '@/components/navigation/Navigation';

type Props = {
  children: ReactNode;
  invertedColor?: boolean;
  formData?: FormQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Layout = ({
  children,
  invertedColor,
  formData,
  footerData,
  headerData,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <main ref={ref} className={clsx('scroll-smooth')}>
      <Navigation data={headerData} invertedColor={invertedColor} />
      {children}
      {formData && <Form data={formData} />}
      <Footer data={footerData} />
    </main>
  );
};
